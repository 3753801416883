<template>
  <div :class="type">
    <shared-show :type="type" :inputs="inputs" :lefts="leftCards" :rights="rightCards" />
  </div>
</template>

<script>
import SharedShow from '@/components/shared/show.vue'

export default {
  name: 'CampaignDetail',
  components: {
    SharedShow
  },
  data() {
    return {
      type: 'campaign',
      inputs: ['cam_name', 'description', 'content', 'cam_link'],
      leftCards: ['banner'],
      rightCards: [
        'cam_display',
        'cam_type',
        'dates',
        'status',
        'parent',
        'address'
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
